import { SERVER_URL } from "api";
import axios from "axios";
import Counter from "components/Counter/Counter";
import Navbar from "components/Navbar";
import PostAside from "components/PostAside/PostAside";
import useCookie from "hooks/useCookie";
import useInterview from "hooks/useInterview";
import useUserInfo from "hooks/useUserInfo";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setInterviewId } from "store/interviewSlice";
import {
  setContents,
  setDraftTypeToScroll,
  setFetchedPost,
  setFetchedPosts,
  setMessages,
  setPodcast,
  setPost,
} from "store/postSlice";
import {
  INTERVIEW_STATUS,
  POST_STATUS,
  showErrorMessage,
  sleep,
} from "Utils";
import LoadingPopUp from "components/Popups/LoadingPopUp/LoadingPopUp";
import "./Dashboard.css";
import DashboardHeader from "components/DashboardHeader";
import TypeSelector from "./components/DraftTypeSelector/TypeSelector";
import DashboardContents from "./components/DashboardContents";
import ScorePopup from "./components/ScorePopup";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { interviewId: id } = useParams();
  const { posts, articles, fetchedPosts, podcast } = useSelector((state) => state.post);
  const { isAuth, isSubscribed, isChecking } = useUserInfo();
  const {
    interviewId,
    interviewStatus,
    fetchInterviewData,
    fetchInterviewScore,
  } = useInterview();

  const [seemsPayed, setSeemsPayed] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [openScore, setOpenScore] = useState(false);

  const { getCookiePayed } = useCookie();

  useEffect(() => {
    setSeemsPayed(!!getCookiePayed());
  }, [getCookiePayed]);

  const aliveRef = useRef(true);

  useEffect(() => {
    return () => {
      aliveRef.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isChecking || !id) return;
      if (fetchedPosts) {
        setFetched(true);
        return;
      }
      try {
        let iIdTemp = interviewId,
          statusTemp = interviewStatus;
        if (id !== interviewId) {
          const interviewData = await fetchInterviewData(id);
          iIdTemp = interviewData.interviewId;
          statusTemp = interviewData.interviewStatus;
          dispatch(setInterviewId(iIdTemp));
        }
        if (statusTemp !== INTERVIEW_STATUS.DONE) {
          navigate(`/meeting/${iIdTemp}`);
          return;
        }
        let count = 0;
        let response = null;
        while (count < 3) {
          response = await axios.get(`${SERVER_URL}/posts/${iIdTemp}`);
          if (response.data.status === POST_STATUS.SUCCESS && response.data.contents.some(content => content.type === "QA")) break;
          if (response.data.status === POST_STATUS.FAILED) {
            count++;
          }
          if (!aliveRef?.current) return;
          await sleep(5000);
        }
        if (count >= 3 && aliveRef?.current) {
          showErrorMessage(
            "Failed to generate contents. Please refresh the page to try again!"
          );
          return;
        }
        dispatch(setContents(response.data.contents));
        await fetchInterviewScore(iIdTemp);
        response = await axios.get(`${SERVER_URL}/messages/${iIdTemp}`);
        dispatch(setMessages(response.data));
        dispatch(setFetchedPosts(true));
        setOpenScore(true);
        setFetched(true);
      } catch (error) {
        console.log(error);
        // showErrorMessage(
        //   error.response?.data?.message || "Internal server error"
        // );
        navigate("/not-found");
      }
    };
    if (!isChecking) fetchData();
  }, [
    dispatch,
    fetchInterviewData,
    fetchInterviewScore,
    fetchedPosts,
    id,
    interviewId,
    interviewStatus,
    isChecking,
    navigate,
  ]);

  useEffect(() => {
    dispatch(setPost(null));
    dispatch(setFetchedPost(false));
  }, [dispatch]);

  useEffect(() => {
    const fetchPodcast = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/interview-sessions/${interviewId}/podcast`)
        dispatch(setPodcast(response.data))
      } catch (error) {
        console.log("Failed to load your podcast details. Please refresh the page and try again.")
      }
    }
    if (!podcast && interviewId) fetchPodcast();
  }, [dispatch, interviewId, podcast]);

  const { draftTypeToScroll } = useSelector((state) => state.post);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const determineTopToScroll = (draftType) => {
      const component = document.querySelector(`.dashboard #${draftType}`);
      if (!component || window.innerWidth > 768) return;

      return (
        component.getBoundingClientRect().top +
        scrollContainerRef.current.scrollTop
      );
    };

    if (scrollContainerRef.current && draftTypeToScroll) {
      const topPosition = determineTopToScroll(draftTypeToScroll);
      if (topPosition !== undefined) {
        console.log(topPosition);
        scrollContainerRef.current.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
        dispatch(setDraftTypeToScroll(null))
      }
    }
  }, [dispatch, draftTypeToScroll]);

  return (
    <div className="dashboard width-animation bg-black-bg flex flex-1 relative">
      {fetched && !showPopup && (
        <>
          {!isAuth && (
            <PostAside
              postsLength={posts.length}
              articlesLength={articles.length}
            />
          )}
          <section
            className="flex-1 h-screen max-h-screen flex flex-col bg-off-white-light overflow-auto scroll-dark"
            ref={scrollContainerRef}
          >
            {!isChecking && !isSubscribed && !seemsPayed && <Counter />}
            <Navbar />
            <DashboardHeader />
            <TypeSelector />
            <DashboardContents />
          </section>
        </>
      )}
      {(showPopup || isChecking) && (
        <LoadingPopUp setShowPopup={setShowPopup} fetched={fetched} />
      )}
      {openScore && !(showPopup || isChecking) && (
        <ScorePopup setOpenPopup={setOpenScore} />
      )}
    </div>
  );
};

export default Dashboard;
