import { createSlice } from "@reduxjs/toolkit";
import { DRAFT_TYPES, IMAGE_MANAGER_TABS } from "Utils";

const initialState = {
  isPreview: false,
  /*
  0 : posts and articles
  1 : edit post
  2 : review post
  3 : done post
  */
  post: null,
  version: -1,
  posts: [],
  answers: [],
  tweets: [],
  threads: [],
  reels: [],
  quotes: [],
  qas: [],
  interviewId: null,
  fetchedPosts: false,
  fetchedPost: false,
  /*
  0 : interviewer speaking
  1 : user speaking
  2 : user paused speaking
  3 : user finished speaking
  4 : user sending recording
  5 : interview finished
  */
  draftTypeForSelector: DRAFT_TYPES.podcast,
  draftTypeToScroll: DRAFT_TYPES.podcast,
  messages: [],
  totalDuration: 0,
  AvgAnswerLength: 0,
  totalWordsCount: 0,
  //
  imageManagerTab: IMAGE_MANAGER_TABS.NO_TAB,
  defaultPrompt: "",
  imageVersion: -1,
  podcast: null
};

const meetingSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload.map((post) => ({
        ...post,
        edited: false,
      }));
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setContents: (state, action) => {
      const posts = [],
        articles = [],
        answers = [],
        tweets = [],
        threads = [],
        reels = [],
        quotes = [],
        qas = [];
      action.payload?.forEach((content) => {
        if (
          content.platform === "LinkedIn" ||
          content.platform === "Linkedin"
        ) {
          if (content.type === "Article") {
            articles.push(content);
          } else posts.push(content);
        } else if (content.platform === "Quora") {
          answers.push(content);
        } else if (content.platform === "Twitter") {
          if (content.type === "Tweet") {
            tweets.push(content);
          } else threads.push(content);
        } else if (content.platform === "Instagram") {
          reels.push(content);
        } else if (content.type === "QA") {
          qas.push(content);
        } else {
          quotes.push(content);
        }
      });
      state.answers = answers;
      state.posts = posts;
      state.articles = articles;
      state.quotes = quotes;
      state.reels = reels;
      state.tweets = tweets;
      state.threads = threads;
      state.qas = qas;
    },
    setPost: (state, action) => {
      const haveToSetVersion =
        !!action.payload &&
        (!state.version ||
          !state.post ||
          state.post._id !== action.payload._id ||
          state.post.contents.length !== action.payload.contents.length);
      state.post = action.payload;
      if (haveToSetVersion) state.version = action.payload.contents.length;
    },
    updatePost: (state, action) => {
      if (state.version === 0 || !state.post?.contents) return;
      let newContents = [...state.post.contents];
      newContents.splice(state.version - 1, 1, action.payload);
      state.post = { ...state.post, contents: newContents };
    },
    setIsPreview: (state, action) => {
      state.isPreview = action.payload;
    },
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setFetchedPosts: (state, action) => {
      state.fetchedPosts = !!action.payload;
    },
    setFetchedPost: (state, action) => {
      state.fetchedPost = !!action.payload;
    },
    setDraftTypeForSelector: (state, action) => {
      state.draftTypeForSelector = action.payload;
    },
    setDraftTypeToScroll: (state, action) => {
      state.draftTypeToScroll = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
      state.totalDuration = action.payload.reduce(
        (sum, message) => sum + message.duration,
        0
      );
      state.totalWordsCount = action.payload.reduce(
        (sum, message) => sum + message.wordsCount,
        0
      );
      state.AvgAnswerLength = action.payload
        .filter((message) => message.role === 0)
        .reduce(
          (sum, message, _, messages) =>
            sum + message.duration / messages.length,
          0
        );
    },
    setImageManagerTab: (state, action) => {
      state.imageManagerTab = action.payload
    },
    setDefaultPrompt: (state, action) => {
      state.defaultPrompt = action.payload.toString();
    },
    setImageVersion: (state, action) => {
      state.imageVersion = action.payload
    },
    setPodcast: (state, action) => {
      state.podcast = action.payload;
    }
  },
});

export const {
  setPosts,
  setArticles,
  setPost,
  setIsPreview,
  updatePost,
  setVersion,
  setCode,
  setFetchedPosts,
  setDraftTypeForSelector,
  setDraftTypeToScroll,
  setContents,
  setMessages,
  setFetchedPost,
  setImageManagerTab,
  setDefaultPrompt,
  setImageVersion,
  setPodcast
} = meetingSlice.actions;

export default meetingSlice.reducer;
