import React, { useEffect, useMemo, useRef, useState } from "react";
import ContentTitle from "./ContentTitle";
import { useDispatch, useSelector } from "react-redux";
import ContentCard from "./ContentCard";
import LinkedinIcon from "assets/images/dashboard/linkedin.svg";
import QuoraIcon from "assets/images/dashboard/Quora icon.png";
import twitterIcon from "assets/images/dashboard/X Icon.svg";
import InstagramIcon from "assets/images/dashboard/instagram.svg";
import RawDataBlock from "./RawData/RawDataBlock";
import DownloadIcon from "assets/images/dashboard/download.svg";
import TranscriptionBlock from "./TranscriptionBlock";
import PodcastBlock from "./Quote_Podcast/PodcastBlock";
import { DRAFT_TYPES } from "Utils";
import { setDraftTypeForSelector } from "store/postSlice";
import axios from "axios";
import { SERVER_URL } from "api";
import useInterview from "hooks/useInterview";
import Button from "components/Button";

const DashboardContents = () => {
  const dispatch = useDispatch();
  const {
    posts,
    articles,
    answers,
    reels,
    tweets,
    threads,
    draftTypeToScroll,
    qas,
  } = useSelector((state) => state.post);
  const { interviewId } = useInterview();
  const scrollContainerRef = useRef(null);
  const containerRef = useRef(null);
  const podcastRef = useRef(null);
  const postsRef = useRef(null);
  const articlesRef = useRef(null);
  const answersRef = useRef(null);
  const threadsRef = useRef(null);
  const tweetsRef = useRef(null);
  const reelsRef = useRef(null);
  const qasRef = useRef(null);
  const rawDataRef = useRef(null);
  const [downloading, setDownloading] = useState(false);

  const refs = useMemo(
    () => ({
      [DRAFT_TYPES.podcast]: podcastRef,
      [DRAFT_TYPES.posts]: postsRef,
      [DRAFT_TYPES.articles]: articlesRef,
      [DRAFT_TYPES.answers]: answersRef,
      [DRAFT_TYPES.threads]: threadsRef,
      [DRAFT_TYPES.tweets]: tweetsRef,
      [DRAFT_TYPES.reels]: reelsRef,
      [DRAFT_TYPES.qas]: qasRef,
      [DRAFT_TYPES.rawData]: rawDataRef,
    }),
    []
  );

  useEffect(() => {
    const determineTopToScroll = (draftType) => {
      if (
        !refs[draftType] ||
        !refs[draftType].current ||
        window.innerWidth <= 768
      )
        return;
      return (
        refs[draftType].current.getBoundingClientRect().top -
        containerRef.current.getBoundingClientRect().top
      );
    };

    if (scrollContainerRef.current && draftTypeToScroll) {
      const topPosition = determineTopToScroll(draftTypeToScroll);
      if (topPosition !== undefined) {
        scrollContainerRef.current.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    }
  }, [draftTypeToScroll, refs]);

  const handleScroll = (e) => {
    if (window.innerWidth <= 768) return;
    const scrollTop = e.target.scrollTop;
    let index = 0;
    if (!scrollContainerRef.current) return;
    while (index < Object.keys(refs).length) {
      const fromElement = Object.values(refs)[index].current;
      if (!fromElement) return;
      const from =
        fromElement.getBoundingClientRect().top -
        containerRef.current.getBoundingClientRect().top;
      let to = scrollContainerRef.current.scrollHeight;
      if (
        index + 1 < Object.keys(refs).length &&
        Object.values(refs)[index + 1].current
      ) {
        to =
          Object.values(refs)[index + 1].current.getBoundingClientRect().top -
          containerRef.current.getBoundingClientRect().top;
      }
      if (scrollTop >= from && scrollTop < to) {
        dispatch(setDraftTypeForSelector(Object.keys(refs)[index]));
        return;
      }
      index++;
    }
    dispatch(setDraftTypeForSelector(DRAFT_TYPES.rawData));
  };

  const downloadFullAudio = async () => {
    if (downloading) return;
    setDownloading(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/interview-sessions/${interviewId}/download/audio`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${interviewId}_full_audio.mp3`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
    setDownloading(false);
  };

  return (
    <div className="flex-1 relative flex flex-col justify-start">
      <div
        className="absolute w-full h-full overflow-x-hidden overflow-y-auto scroll-dark dashboard-contents-scroll-container"
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        <div
          className="flex flex-col w-full items-start gap-4 px-4 sm:px-7 pb-7"
          ref={containerRef}
        >
          <ContentTitle
            platform="Quotes & Podcasts"
            isDraft={false}
            ref={refs[DRAFT_TYPES.podcast]}
            id={DRAFT_TYPES.podcast}
          />
          <PodcastBlock />
          {posts?.length > 0 && (
            <>
              <ContentTitle
                count={posts.length}
                platform="Linkedin"
                type="Post"
                ref={refs[DRAFT_TYPES.posts]}
                id={DRAFT_TYPES.posts}
              />
              <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
                {posts.map((post, index) => (
                  <ContentCard key={index} post={post} icon={LinkedinIcon} />
                ))}
              </div>
            </>
          )}
          {articles?.length > 0 && (
            <>
              <ContentTitle
                count={articles.length}
                platform="Linkedin"
                type="Article"
                ref={refs[DRAFT_TYPES.articles]}
                id={DRAFT_TYPES.articles}
              />
              <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
                {articles.map((post, index) => (
                  <ContentCard key={index} post={post} icon={LinkedinIcon} />
                ))}
              </div>
            </>
          )}

          {answers?.length > 0 && (
            <>
              <ContentTitle
                count={answers.length}
                platform="Quora"
                type="Answer"
                ref={refs[DRAFT_TYPES.answers]}
                id={DRAFT_TYPES.answers}
              />
              <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
                {answers.map((answer, index) => (
                  <ContentCard key={index} post={answer} icon={QuoraIcon} />
                ))}
              </div>
            </>
          )}
          {threads?.length > 0 && (
            <>
              <ContentTitle
                count={threads.length}
                platform="X"
                type="Thread"
                ref={refs[DRAFT_TYPES.threads]}
                id={DRAFT_TYPES.threads}
              />
              <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
                {threads.map((thread, index) => (
                  <ContentCard key={index} post={thread} icon={twitterIcon} />
                ))}
              </div>
            </>
          )}
          {tweets?.length > 0 && (
            <>
              <ContentTitle
                count={tweets.length}
                platform="X"
                type="Tweet"
                ref={refs[DRAFT_TYPES.tweets]}
                id={DRAFT_TYPES.tweets}
              />
              <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
                {tweets.map((tweet, index) => (
                  <ContentCard key={index} post={tweet} icon={twitterIcon} />
                ))}
              </div>
            </>
          )}

          {reels?.length > 0 && (
            <>
              <ContentTitle
                count={reels.length}
                platform="Instagram"
                type="Reel"
                ref={refs[DRAFT_TYPES.reels]}
                id="reels"
              />
              <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
                {reels.map((reel, index) => (
                  <ContentCard key={index} post={reel} icon={InstagramIcon} />
                ))}
              </div>
            </>
          )}
          {qas?.length > 0 && (
            <>
              <ContentTitle
                count={qas.length}
                platform="Linkedin Q&A"
                type=""
                ref={refs[DRAFT_TYPES.qas]}
                id="qas"
              />
              <div className="w-full grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
                {qas.map((qa, index) => (
                  <ContentCard key={index} post={qa} icon={LinkedinIcon} />
                ))}
              </div>
            </>
          )}
          <ContentTitle
            platform="Raw Data"
            isDraft={false}
            ref={refs[DRAFT_TYPES.rawData]}
            id={DRAFT_TYPES.rawData}
          />
          <RawDataBlock />
          <div className="flex justify-end w-full">
            <Button
              icon={DownloadIcon}
              alt="download all"
              iconClassName="size-[18px] flex-shrink-0"
              className="gap-2 font-figtree text-[13px] leading-[22px] tracking-[0.46px] font-[700]"
              onClick={downloadFullAudio}
              disabled={downloading}
              variant="ghost"
              text="Download full audio"
            />
          </div>
          <TranscriptionBlock />
        </div>
      </div>
    </div>
  );
};

export default DashboardContents;
