import React, { useEffect, useMemo, useRef, useState } from "react";
import playIcon from "assets/images/dashboard/Play button.svg";
import linkedinIcon from "assets/images/dashboard/linkedin.svg";
import quoraIcon from "assets/images/dashboard/Quora icon.png";
import twitterIcon from "assets/images/dashboard/X Icon.svg";
import instagramIcon from "assets/images/dashboard/instagram.svg";
import rawDataIcon from "assets/images/dashboard/data.svg";
import { DRAFT_TYPES } from "Utils";
import DraftTypeCard from "./DraftTypeCard";
import { useDispatch, useSelector } from "react-redux";
import { setDraftTypeToScroll } from "store/postSlice";

const TypeSelector = () => {
  const dispatch = useDispatch();
  const [left, setLeft] = useState(28);
  const {
    draftTypeForSelector,
    posts,
    articles,
    answers,
    reels,
    tweets,
    threads,
    qas,
  } = useSelector((state) => state.post);
  const dragStartRef = useRef(null);
  const containerRef = useRef(null);
  const controllerRef = useRef(null);
  const paddingRef = useRef(28);
  const items = useMemo(
    () => [
      {
        icon: playIcon,
        text: "Quotes & Podcast",
        value: DRAFT_TYPES.podcast,
        disabled: false,
      },
      {
        icon: linkedinIcon,
        text: "Posts",
        value: DRAFT_TYPES.posts,
        disabled: !(posts?.length > 0),
      },
      {
        icon: linkedinIcon,
        text: "Articles",
        value: DRAFT_TYPES.articles,
        disabled: !(articles?.length > 0),
      },
      {
        icon: quoraIcon,
        text: "Answers",
        value: DRAFT_TYPES.answers,
        disabled: !(answers?.length > 0),
      },
      {
        icon: twitterIcon,
        text: "X-Threads",
        value: DRAFT_TYPES.threads,
        disabled: !(threads?.length > 0),
      },
      {
        icon: twitterIcon,
        text: "X-Tweets",
        value: DRAFT_TYPES.tweets,
        disabled: !(tweets?.length > 0),
      },
      {
        icon: instagramIcon,
        text: "Reels",
        value: DRAFT_TYPES.reels,
        disabled: !(reels?.length > 0),
      },
      {
        icon: linkedinIcon,
        text: "Q&A",
        value: DRAFT_TYPES.qas,
        disabled: !(qas?.length > 0),
      },
      {
        icon: rawDataIcon,
        text: "Raw Data",
        value: DRAFT_TYPES.rawData,
      },
    ],
    [
      answers?.length,
      articles?.length,
      posts?.length,
      reels?.length,
      threads?.length,
      tweets?.length,
      qas?.length,
    ]
  );

  useEffect(() => {
    const handleDrag = (e) => {
      const clientX = e.clientX || e.touches[0].clientX;
      if (dragStartRef.current === null) return;
      const padding = paddingRef.current;
      const controllerWidth = 1172.31 + 28 * 3;
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      console.log(controllerWidth, containerWidth);
      setLeft((prev) => {
        if (2 * padding + controllerWidth < containerWidth) return padding;
        const newValue = prev + clientX - dragStartRef.current;
        if (newValue > padding) return padding;
        if (newValue + controllerWidth - containerWidth + padding < 0)
          return -controllerWidth + containerWidth - padding;
        dragStartRef.current = newValue - prev + dragStartRef.current;
        return newValue;
      });
    };
    const handleDown = (e) => {
      const clientX = e.clientX || e.touches[0].clientX;

      if (controllerRef.current && controllerRef.current.contains(e.target)) {
        dragStartRef.current = clientX;
      }
    };
    const handleUp = () => {
      dragStartRef.current = null;
    };
    const handleScroll = (e) => {
      const scrollAmount = e.deltaY / 5;
      const padding = paddingRef.current;
      const controllerWidth = 1172.31 + 28 * 3;
      const containerWidth = containerRef.current.getBoundingClientRect().width;

      setLeft((prev) => {
        if (2 * padding + controllerWidth < containerWidth) return padding;
        const newValue = prev - scrollAmount;
        console.log(prev, newValue, padding);
        if (newValue >= padding) return padding;
        if (newValue + controllerWidth - containerWidth + padding < 0)
          return -controllerWidth + containerWidth - padding;
        return newValue;
      });
    };
    const handleResize = () => {
      const padding = paddingRef.current;
      setLeft(padding);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("mousedown", handleDown);
    window.addEventListener("mouseup", handleUp);
    window.addEventListener("touchmove", handleDrag);
    window.addEventListener("touchstart", handleDown);
    window.addEventListener("touchend", handleUp);

    const container = containerRef.current;
    container.addEventListener("wheel", handleScroll);
    return () => {
      window.addEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mousedown", handleDown);
      window.removeEventListener("mouseup", handleUp);
      window.removeEventListener("touchmove", handleDrag);
      window.removeEventListener("touchstart", handleDown);
      window.removeEventListener("touchend", handleUp);

      if (container) {
        container.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  const handleClick = (value) => {
    dispatch(setDraftTypeToScroll(value));
  };

  return (
    <div
      className="w-full overflow-hidden hide-scrollbar relative h-[42px] my-4 flex-shrink-0"
      ref={containerRef}
    >
      <div
        className="absolute flex items-center gap-3"
        style={{ left: `${left}px` }}
        ref={controllerRef}
      >
        {items.map((item, index) => (
          <DraftTypeCard
            key={index}
            text={item.text}
            icon={item.icon}
            onClick={() => handleClick(item.value)}
            isPodcast={item.value === draftTypeForSelector}
            disabled={item.disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default TypeSelector;
